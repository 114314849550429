import React from 'react'
import "../App.css";
import "../index.css";

const Navigation = () => {
  return (
    <div className="bg-gray-800 navbar">
      <div className="h-16 px-8 flex items-center navbar-header">
        <p className="text-white font-bold">College Result CRUD App</p>
      </div>
    </div>
  );
}

export default Navigation
